<template>
    <div>
        <v-btn
            :id="`${id}-head-cart-user-menu-button`"
            :key="`${id}-head-cart-user-menu-button`"
            icon
            class="head-cart-item"
            :color="user?.id ? 'cis-accent-color' : undefined"
            @click="handleUserClick"
        >
            <v-icon dark :icon="mdilAccount" />
        </v-btn>
        <v-menu
            :activator="`#${id}-head-cart-user-menu-button`"
            :model-value="showMenu"
            :open-on-hover="false"
            :close-on-content-click="true"
            @update:model-value="(e) => showMenu = e"
        >
            <v-list>
                <Link v-if="$page.props.favorites" :href="route('customer.favorites')" style="color: inherit">
                    <v-list-item>
                        <template #prepend>
                            <v-badge offset-x="-5" color="green" :model-value="$page.props.favorites.length > 0" :content="$page.props.favorites.length">
                                <v-icon :icon="mdiHeart"/>
                            </v-badge>
                        </template>
                        <template #default>
                            {{ $t('eshop::customer-layout.favorites') }}
                        </template>
                    </v-list-item>
                </Link>
                <Link class="text-black" :href="route('customer.dashboard')" :onFinish="() => showMenu = false">
                    <v-list-item :prepend-icon="mdiAccountCard">
                        {{ $t('login-btn.account') }}
                    </v-list-item>
                </Link>
                <v-divider />
                <v-list-item @click="logOut" :prepend-icon="mdiLogoutVariant">
                    {{ $t('login-btn.logoff') }}
                </v-list-item>
            </v-list>
        </v-menu>
        <v-dialog v-model="showReg" scrollable max-width="600">
            <login-form @closeLoginDialog="showReg = false" />
        </v-dialog>
    </div>
</template>

<script setup>
import { computed, defineAsyncComponent, inject, ref, watch } from 'vue';
import { router, Link, usePage } from '@inertiajs/vue3';
import { mdiAccountCard, mdiAccountCircle, mdiHeart, mdiLogoutVariant } from '@mdi/js';
import { VProgressCircular } from 'vuetify/components';
import { mdilAccount } from '@mdi/light-js';

const props = defineProps({
    id: {
        type: [Number, String],
        required: true,
    },
    openLogin: Boolean,
});
const showMenu = ref(false);
const user = computed(() => usePage().props.customer);
const showReg = ref(false);

const route = inject('route');
const logOut = () => {
    router.post(route('customer.logout'));
    showMenu.value = false;
};

const handleUserClick = (event) => {
    event.stopPropagation();
    event.stopImmediatePropagation();
    event.preventDefault();
    if (!user.value || !user.value.id) {
        showReg.value = !showReg.value;
    } else {
        showMenu.value = !showMenu.value;
    }
};

const LoginForm = defineAsyncComponent({
    loader: () => import('../../modules/eshop/frontend/Login.vue'),
    loadingComponent: VProgressCircular,
});

const openLogin = computed(() => props.openLogin);
watch(openLogin, (val) => {
    if (val && (!user.value || !user.value.id)) {
        showReg.value = true;
    }
}, { immediate: true });
</script>

<style scoped>

</style>
