<template>
    <template v-if="isMounted"><slot></slot></template>
    <template v-else-if="$slots.placeholder"><slot name="placeholder"></slot></template>
</template>

<script setup>
import { ref, onMounted } from 'vue'
const isMounted = ref(false)
onMounted(() => {
    isMounted.value = true
})
</script>
