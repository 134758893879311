import Echo from 'laravel-echo';
import Pusher from "pusher-js";

try {
    if(typeof window !== "undefined") {
        window.Pusher = Pusher;
        window.Echo = new Echo({
            broadcaster: 'pusher',
            key: import.meta.env.VITE_PUSHER_APP_KEY,
            wsHost: import.meta.env.VITE_APP_DOMAIN,
            wsPort: window.location.port,
            wssPort: window.location.port,
            forceTLS: false,
            encrypted: true,
            disableStats: true,
            enabledTransports: ['ws', 'wss'],
            cluster: import.meta.env.VITE_PUSHER_APP_CLUSTER
        });
    }
} catch (e) {
    console.error(e);
}
