<template>
    <div v-if="breadcrumbs.length > 1">
        <v-container class="py-0 durance-breadcrumbs">
            <v-breadcrumbs  :items="items" class="px-0 flex-wrap">
                <template v-slot:divider>
                    <v-icon :icon="mdiChevronRight"></v-icon>
                </template>
                <template v-slot:title="{ item }">
                    <Link :href="item.url" class="d-inline-flex align-center text-black to-warning">
                        <v-icon v-if="item.url === '/'" id="homepage-icon" :icon="mdiHome"></v-icon>
                        <span v-else>{{ item.title }}</span>
                    </Link>
                </template>
            </v-breadcrumbs>
        </v-container>
        <v-divider></v-divider>
    </div>
</template>

<script setup>
import {mdiHome, mdiChevronRight} from "@mdi/js";
import {Link} from "@inertiajs/vue3";
import {computed} from "vue";

const props = defineProps({
    items: Array
})
const breadcrumbs = computed(() => {
    const crumb = [];
    if(props.items) {
        let last = 0;
        props.items.forEach((item, index) => {
            crumb[index] = {
                ...item
            };
            crumb[index].disabled = false;
            last = index;
        });
        crumb[last].disabled = true;
    }
    return crumb;
})
</script>

<style scoped lang="scss">
#homepage-icon{
    font-size: 1.2em;
}
.durance-breadcrumbs {
    --v-disabled-opacity: 1;
}
</style>
