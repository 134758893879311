import {nextTick, watch} from "vue";
import {usePage} from "@inertiajs/vue3";
import {notify} from "@kyvg/vue3-notification";
import { useGoTo } from 'vuetify'

export function useNotify(findErrors = true) {
    const goto = useGoTo();
    watch(() => usePage().props.flash, (flash) => {
        if(flash && flash.type && flash.message){
            nextTick(() => {
                notify({
                    text: flash.message,
                    type: flash.type,
                    title: flash.title,
                    id: flash.id,
                    duration: flash.duration,
                    data: flash.data,
                });
            })
        }
    }, {
        immediate: true
    });
    watch(() => usePage().props.errors, (errors) => {
        for (const errorsKey in errors) {
            if(typeof errors[errorsKey] !== 'object'){
                nextTick(() => {
                    notify({
                        text: errors[errorsKey],
                        type: 'error',
                    });
                })
            } else if(findErrors && !import.meta.env.SSR) {
                nextTick (() => {
                    const el = document.querySelector('.v-messages__message');
                    if(el) {
                        goto(el, {
                            duration: 300,
                            easing: 'easeInOutCubic',
                            offset: -200,
                        });
                    }
                })

            }
        }
    }, {
        immediate: true
    });
}
