import {usePage} from "@inertiajs/vue3";
import {isRef, unref} from "vue";

export const addEvent = (event) => {
    if (typeof window !== "undefined") {
        window.gtag({ecommerce: null})
        window.gtag(event)
    }
}

export const generateItem = (product, itemAttributes) => {
    let gtmItem = {
        item_id: product.id,
        item_name: product.name,
        price: itemAttributes?.price || parseFloat(product.price.replace(/\s/g, '').replace(/,/g, '.')).toFixed(2),
        quantity: 1,
    }
    if (product.manufacturer) {
        gtmItem['item_brand'] = product.manufacturer.name;
    }
    if (product.all_groups?.length) {
        for (let i = 0; i < product.all_groups.length; i++) {
            if (i > 5) break;
            gtmItem[`item_category${i !== 0 ? i : ''}`] = product.all_groups[i].name;
        }
    } else if (product.default_group) {
        gtmItem['item_category'] = product.default_group.name;
    }
    if (itemAttributes) {
        gtmItem = Object.assign({}, gtmItem, itemAttributes)
    }
    return gtmItem;
}

export const removeFromCart = (cartItems, id) => {
    const item = cartItems.find((item) => item.id == id);
    if (item) {
        const price = parseFloat(item.nice_price.replace(/\s/g, '').replace(/,/g, '.'));
        const attributes = {
            quantity: item.count,
            price: price.toFixed(2)
        };
        addEvent({
            event: 'remove_from_cart',
            ecommerce: {
                currency: usePage().props.currency,
                value: price,
                items: generateItem(item.products, attributes)
            }
        })
    }
}

let updateCartRunning = false;
let viewCartRunning = false;

export const updateCart = (cartItems, oldItem, id) => {
    if (updateCartRunning) return;
    updateCartRunning = true;
    const item = cartItems.find((item) => item.id == id);
    if (item) {
        const price = parseFloat(item.nice_price.replace(/\s/g, '').replace(/,/g, '.'));
        const attributes = {
            quantity: Math.abs(oldItem.count - item.count),
            price: price.toFixed(2)
        };
        addEvent({
            event: oldItem.count < item.count ? 'add_to_cart' : 'remove_from_cart',
            ecommerce: {
                currency: usePage().props.currency,
                value: price,
                items: generateItem(item.products, attributes)
            }
        })
    }
    updateCartRunning = false;
}

const prepareBaseEvent = (cartItems, event, additionalAttributes = {}) => {
    const price = parseFloat(usePage().props.order.nice_total_price.replace(/\s/g, '').replace(/,/g, '.'))
    if(isRef(cartItems)){
        cartItems = unref(cartItems);
    }
    return {
        event,
        ecommerce: {
            currency: usePage().props.currency,
            value: price.toFixed(2),
            ...additionalAttributes,
            items: (() => {
                const items = [];
                for (let i = 0; i < cartItems.length; i++) {
                    items.push(generateItem(cartItems[i].products,
                            {
                                price: parseFloat(cartItems[i].nice_price.replace(/\s/g, '').replace(/,/g, '.')),
                                quantity: cartItems[i].count
                            }
                        )
                    )
                }
                return items;
            })(),
        }
    };
}

export const viewCart = (cartItems, withBeginCheckout = false, event = 'view_cart', additionalAttributes = {}) => {
    if (viewCartRunning) return;
    viewCartRunning = true;
    const eventObj = prepareBaseEvent(cartItems, event, additionalAttributes);
    if(withBeginCheckout){
        beginCheckout(eventObj);
        eventObj.event = event;
    }
    addEvent(eventObj)
    viewCartRunning = false;
}

const beginCheckout = (event) => {
    const eventObj = Object.assign({}, event);
    eventObj.event = 'begin_checkout';
    addEvent(eventObj)
}

export const addShippingInfo = (cartItems) => {
    addEvent(prepareBaseEvent(cartItems, 'add_shipping_info'));
}

export const addDeliveryInfo = (cartItems, payment) => {
    if(payment.name) {
        addEvent(prepareBaseEvent(cartItems, 'add_delivery_info', {payment_type: payment.name}));
    }
}

export const purchase = (cartItems) => {
    addEvent(prepareBaseEvent(cartItems, 'purchase', {transaction_id: usePage().props.order.id}));
}
