import "vuetify/styles";
import {createVuetify} from "vuetify";
import { aliases, mdi } from 'vuetify/iconsets/mdi-svg';
import { cs, en } from 'vuetify/locale'
import colorManipulator from '../utils/colorManipulator.js';
const defaultAccent = '#DBA1A1';

export default function(color) {
    return createVuetify({
        ssr: import.meta.env.SSR,
        theme: {
            options: {
                customProperties: true
            },
            themes: {
                light: {
                    colors: {
                        'cis-accent-color': color || defaultAccent,
                        'cis-accent-color-lighten-1': colorManipulator(color || defaultAccent, 20),
                        'cis-accent-color-lighten-2': colorManipulator(color || defaultAccent, 40),
                        'cis-accent-color-lighten-3': colorManipulator(color || defaultAccent, 60),
                        'cis-accent-color-lighten-4': colorManipulator(color || defaultAccent, 80),
                        'cis-accent-color-lighten-5': colorManipulator(color || defaultAccent, 100),
                        'durance-grey': '#888B90',
                        'durance-pink': color || defaultAccent,
                    }
                }
            }
        },
        icons: {
            defaultSet: 'mdi',
            aliases,
            sets: {
                mdi,
            },
        },
        locale: {
            locale: 'cs',
            fallback: 'en',
            messages: { cs, en },
        },
        defaults: {
            VImg: {crossOrigin: true}
        },
    });
}
