import {ref} from "vue";
import axios from "axios";
import {skipHydrate} from "pinia";

export const makeStore = () => {
    const hits = ref([]);
    const query = ref('');
    let searching = false;
    const search = async (query, url) => {
        if(!searching) {
            searching = true;
            return axios.get(url, {params: {q: query}}).then(resp => {
                searching = false;
                hits.value = resp.data
            })
        }
    };

    const logQuery = (query, url) => {
        return axios.post(url, {query})
    };

    return { hits: skipHydrate(hits), query: skipHydrate(query), search, logQuery }
}
