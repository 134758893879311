<script setup>
import { Link, usePage } from '@inertiajs/vue3';
import { ref } from 'vue';

defineProps({
    items: Array,
    shrink: Boolean,
    logo: Object
});
const categoryHeaderStyle = (group) => {
    const { defaultCategoryHeader } = usePage().props;
    const url = group?.category_header?.url || defaultCategoryHeader?.url;

    return {
        style: url ? { backgroundImage: `url(${url})` } : { backgroundImage: `url(${new URL('../../assets/category-header.webp', import.meta.url).href})` },
        class: url ? 'has-custom-bg' : ''
    };
}
</script>

<template>
    <div class="d-flex">
        <v-hover v-for="item in items" :key="item.id">
            <template v-slot:default="{ isHovering, props }">
                <Link
                    :href="item.url"
                    class="text-caption font-weight-bold px-2 px-lg-6 cursor-pointer position-relative text-black"
                    v-bind="props"
                    :class="[isHovering ? 'text-decoration-underline':'', shrink || $vuetify.display.mdAndDown ? '': 'py-10']">
                    {{ item.name }}
                </Link>
                <teleport to="body">
                    <div v-bind="props" v-if="isHovering" class="horizontal-menu" :class="shrink ? 'shrink' : ''">
                        <v-container>
                            <div class="py-8 px-10 bg-white elevation-12 elevation-6 d-flex">
                                <div class="w-50">
                                    <p class="text-uppercase text-cis-accent-color font-weight-bold mb-4">
                                        {{item.name}}
                                    </p>
                                    <div class="c-grid ga-2 w-auto">
                                        <v-hover v-for="(children, i) in item.children">
                                            <template v-slot:default="{ isHovering, props }">
                                                <Link :href="children.url" class="hm-item cursor-pointer text-black"
                                                      v-bind="props"
                                                      :class="isHovering ? 'text-decoration-underline':''">
                                                    {{ children.name }}
                                                </Link>
                                            </template>
                                        </v-hover>
                                    </div>
                                </div>
                                <v-spacer></v-spacer>
                                <div class="position-relative">
                                    <img :src="logo?.src" :alt="logo?.alt" width="110px" class="durance-logo" />
                                    <div class="category-illustration" :class="categoryHeaderStyle(item).class" :style="categoryHeaderStyle(item).style"></div>
                                </div>
                            </div>
                        </v-container>
                    </div>
                </teleport>
            </template>
        </v-hover>
        <v-hover v-for="link in $page.props.menu_links" :key="link.id">
            <template v-slot:default="{ isHovering, props }">
                <Link v-bind="props"
                      v-if="link.type === 'inside' && link.target === '_self'"
                      :class="[isHovering ? 'text-decoration-underline':'', shrink || $vuetify.display.mdAndDown ? '': 'py-10']"
                      class="text-body-2 px-2 px-lg-6 cursor-pointer position-relative text-black"
                      :href="link.url">
                    {{ link.label }}
                </Link>
                <a
                    v-bind="props"
                    v-else
                    :href="link.url"
                    :target="link.target"
                    :class="[isHovering ? 'text-decoration-underline':'', shrink || $vuetify.display.mdAndDown ? '': 'py-10']"
                    class="text-body-2 px-2 px-lg-6 cursor-pointer position-relative text-black"
                >
                    {{ link.label }}
                </a>
            </template>
        </v-hover>
    </div>
</template>

<style scoped lang="scss">
.horizontal-menu {
    position: fixed;
    width: 100%;
    top: 138px;
    z-index: 9999;
    transition: top .1s ease;

    &.shrink {
        top: 38px;
    }
}
.c-grid {
    display: grid;
    grid-template-columns: 3fr 3fr;
}
.category-illustration
{
    width: 300px;
    min-height: 200px;
    background-position: center;
    background-size: cover;
    border-radius: 16px;
    &.has-custom-bg {
       filter: brightness(1.2);
        opacity: .2;
    }
}
.durance-logo {
    position: absolute;
    filter: alpha(white);
    bottom: 20px;
    mix-blend-mode: darken;
    left: -30px
}
</style>
