<template>
    <Head :title="title ? title : $t('cis-head.default')"/>
</template>

<script setup>
import {Head} from "@inertiajs/vue3";

defineProps({
    title: String
})
</script>
